import React from "react"
import MainLayout from "../components/mainLayout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import home4 from '../images/home4.jpg'
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import style from "./services.module.scss"

const ServicesPage = () => {
  const content = useStaticQuery(graphql`
    query {
      sheila: file(relativePath: { eq: "services_sheila.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      board: file(relativePath: { eq: "services_board.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cyclebar: file(relativePath: { eq: "services_cyclebar.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dunkin: file(relativePath: { eq: "services_dunkin.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      marketing: file(relativePath: { eq: "services_marketing.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      posters: file(relativePath: { eq: "services_posters.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      speaking: file(relativePath: { eq: "services_speaking.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      thrival: file(relativePath: { eq: "services_thrival.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  
  return (
    <MainLayout>
      <SEO title="Services" />
      <Banner primaryText={'Services'} backgroundImg={home4}/>
      <div className={style.servicesGrid}>
        <div>
          <Img fluid={content.sheila.childImageSharp.fluid} />
          <div>
            <h2>Event Planning + Production</h2>
            <p>Pitch Consulting takes an innovative approach to develop experiential events that take our clients’ vision to new levels.  We create share-worthy, interactive, and memorable moments through immersive storytelling at each of our unique experiences.  Events include music festivals, corporate and non-profit events, concerts, and more.</p>
          </div>
        </div>
        <div>
          <div>
            <h2>Sponsorship: Strategy, Sales, Marketing & On-Site Activations</h2>
            <p>We’ve worked with some of the biggest brands, companies, events and music artists, offering a “full service” sponsorship solution from strategy to implementation.</p>
          </div>
          <Img fluid={content.dunkin.childImageSharp.fluid} />
        </div>
        <div>
          <Img fluid={content.posters.childImageSharp.fluid} />
          <div>
            <h2>Public Relations</h2>
            <p>We rely on our network of relationships, focused targeting, and relevant pitching, to secure engaging print, digital, and multimedia spots to position our clients at the forefront of their industry.</p>
          </div>
        </div>
        <div>
          <div>
            <h2>Strategic Partnerships</h2>
            <p>We develop partnership campaigns and bring the right brands together to collaborate on strategic marketing and fundraising campaigns, to help each partner achieve their objectives.</p>
          </div>
          <Img fluid={content.cyclebar.childImageSharp.fluid} />
        </div>
        <div>
          <Img fluid={content.marketing.childImageSharp.fluid} />
          <div>
            <h2>Social Media: Content Development, Strategy, Influencer Marketing + Account Management</h2>
            <p>Pitch Consulting caters to brands, artists and individuals looking to elevate their visual content and social presence.  We create brand strategies to ideally position our clients in the marketplace, content strategies to help our clients reach the right customers, and customized campaigns to achieve our clients’ optimal results.</p>
          </div>
        </div>
        <div>
          <div>
            <h2>Pitch Deck Creation + Design</h2>
            <p>The most important tool when selling sponsorships, your event, or brand, is a professionally designed pitch deck. Many times the first impression of your pitch deck is the difference between securing a meeting, or your email going to the trash. Let us create and design your pitch deck using compelling sales content, demographic stats that speak to your audience, engaging photos, and sponsorship packages that are ready to sell.</p>
          </div>
          <Img fluid={content.thrival.childImageSharp.fluid} />
        </div>
        <div>
          <Img fluid={content.speaking.childImageSharp.fluid} />
          <div>
            <h2>Sales Training</h2>
            <p>Pitch Consulting offers sales training that combines proven sales performance and intelligence strategies and processes to enhance your team’s sales culture and improve productivity.</p>
          </div>
        </div>
        <div>
          <div>
            <h2>Music Business + Artist Consulting</h2>
            <p>We help artists navigate the current day music business, develop their brand, create a digital marketing presence and strategy, and foster artist development.</p>
          </div>
          <Img fluid={content.board.childImageSharp.fluid} />
        </div>
      </div>
    </MainLayout>
  )
}

export default ServicesPage
